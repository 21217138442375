import { Roles } from 'types'
import { useAppSelector } from 'utils'

/**
 * Roles Hook
 *
 * Add roles logic to the app here, based on cerbere permissions or other role-based access control
 *
 * @returns rolesLoaded: boolean, hasAccess: boolean
 */
export const useRoles = () => {
  const { appPermissions, isLoading, isLogged } = useAppSelector(state => state.user)
  const roles = appPermissions.filter(role => role.includes('dexcarto::'))

  return {
    rolesLoaded: isLogged && !isLoading,
    needAuthorization: !roles.includes(Roles.USER),
    isAdmin: roles.includes(Roles.ADMIN),
    isUser: roles.includes(Roles.USER),
  }
}
