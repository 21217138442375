import { Layer, Source } from 'react-map-gl'
import { MapDataSignal, CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { useActivePerimeterParams, useMapURL } from 'utils'

export default function BALLayer({ mapRef, view, visibility }: LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  const url = useMapURL(
    CHARTIS_SOURCES_IDS.bal,
    view,
    CHARTIS_SOURCES_IDS.bal,
    mapRef,
    perimeterParam,
  )
  const hoveredObjectsIds = MapDataSignal.hoveredObjects.value?.map(o => o.properties?.id) || []
  const { opacity, lineFilter } = MapDataSignal

  return (
    <Source
      id={CHARTIS_SOURCES_IDS.bal}
      type="vector"
      url={url}
    >
      <Layer
        id={MAPBOX_LAYER_IDS.balFill}
        type="fill"
        layout={{
          visibility: visibility ? 'visible' : 'none',
        }}
        paint={{
          'fill-color': ['coalesce', ['get', 'color'], '#000000'],
          'fill-opacity': ['case',
            ['in', lineFilter.value, ['get', 'lrs_ligne']], 0.25,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0.25,
            0.25 * (opacity || 1)],
        }}
        minzoom={7}
        source-layer={CHARTIS_SOURCES_IDS.bal}
      />
      <Layer
        type="fill"
        id={MAPBOX_LAYER_IDS.balDot}
        source-layer={CHARTIS_SOURCES_IDS.bal}
        paint={{
          'fill-pattern': 'dot',
          'fill-opacity': ['case',
            ['in', lineFilter.value, ['get', 'lrs_ligne']], 0.1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0.1,
            0.1 * (opacity || 1)],
        }}
        layout={{
          visibility: visibility ? 'visible' : 'none',
        }}
        minzoom={7}
      />
      <Layer
        type="line"
        id={MAPBOX_LAYER_IDS.balOutline}
        source-layer={CHARTIS_SOURCES_IDS.bal}
        paint={{
          'line-color': '#CC0000',
          'line-width': 2,
          'line-opacity': ['case',
            ['in', lineFilter.value, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        filter={['in', ['get', 'id'], ['literal', hoveredObjectsIds]]}
        layout={{
          visibility: visibility ? 'visible' : 'none',
        }}
        minzoom={7}
      />
      <Layer
        type="symbol"
        id={MAPBOX_LAYER_IDS.balLabel}
        source-layer={CHARTIS_SOURCES_IDS.bal}
        layout={{
          'text-field': ['get', 'libelle'],
          'text-size': 14,
          'text-anchor': 'center',
          'text-allow-overlap': false,
          visibility: visibility ? 'visible' : 'none',
          'symbol-placement': 'line',
          'text-font': ['Open Sans Bold'],
          'symbol-spacing': 250,
        }}
        paint={{
          'text-color': ['case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], '#cc0000',
            ['get', 'color'],
          ],
          'text-halo-color': '#FFFFFF',
          'text-halo-width': 1,
        }}
        minzoom={7}
      />
    </Source>
  )
}
