type ToolWrapperProps = {
  title: string;
  children: React.ReactNode;
  animate?: boolean;
  separator?: boolean;
}

export default function ToolWrapper({ title, children, animate, separator = true }: ToolWrapperProps) {
  return (
    <div className={`tool-wrapper${animate ? ' to-animate' : ''}`}>
      <h3>{title}</h3>
      <div className="list">
        {children}
      </div>
      {separator && <div className="separator" />}
    </div>
  )
}
