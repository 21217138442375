import { CHANTIER_LAYERS_PHASES, CHANTIER_LAYERS_YEARS, POSTES_LAYER_KEYS } from 'assets/layers'
import { Polygon } from 'geojson'
import { get } from '@osrdata/app_core/dist/requests'
import { OnScreenBALSearchResult, OnScreenChantierSearchResult, OnScreenPosteSearchResult } from 'types'
import { isLayerSelected } from './layers'
import { CHARTIS_SOURCES_IDS } from './map'

export const getOnScreenPoste = async (
  bbox: Polygon,
  view: 'sch' | 'geo',
  additionalParams?: Record<string, string>,
) => {
  const posteTypes = Object.entries(POSTES_LAYER_KEYS).filter(([, value]) => isLayerSelected(value)).map(([key]) => key)
  if (!posteTypes.length) return false
  const viewParam = view === 'sch' ? 'full_rgi_track_sch_flat_centroid' : 'full_rgi_track_geo_centroid'
  const posteResponse = await get<OnScreenPosteSearchResult>(
    `/chartis/v2/layer/${CHARTIS_SOURCES_IDS.poste}/search/${viewParam}/`,
    {
      // eslint-disable-next-line max-len
      columns: 'id,libelle_long,type_installation_fixe_id_mnemo,libelle,lrs_ligne,lrs_voie,lrs_pk',
      bbox,
      size_per_layer: 50,
      ...(posteTypes.length ? { type_installation_fixe_id_mnemo__in: posteTypes.join(',') } : {}),
      ...additionalParams,
    },
  )
  return posteResponse
}

export const getOnScreenBAL = async (
  bbox: Polygon,
  view: 'sch' | 'geo',
  additionalParams?: Record<string, string>,
) => {
  const viewParam = view === 'sch' ? 'full_rgi_track_sch_flat' : 'full_rgi_track_geo'
  const balResponse = await get<OnScreenBALSearchResult>(
    `/chartis/v2/layer/${CHARTIS_SOURCES_IDS.bal}/search/${viewParam}/`,
    {
      columns: 'id,libelle,lrs_ligne,lrs_pk',
      bbox,
      size_per_layer: 50,
      ...additionalParams,
    },
  )
  return balResponse
}

export const getOnScreenChantier = async (
  bbox: Polygon,
  view: 'sch' | 'geo',
  additionalParams?: Record<string, string>,
) => {
  const years = Object.entries(CHANTIER_LAYERS_YEARS).filter(([, value]) => isLayerSelected(value)).map(([key]) => key)
  const phases = Object.entries(CHANTIER_LAYERS_PHASES).filter(([, value]) => isLayerSelected(value))
    .map(([key]) => key)
  if (!years.length || !phases.length) return false
  const filterASTRE = isLayerSelected('chantier-astre') || isLayerSelected('chanter-astre-and-seism')
  const filterSEISM = isLayerSelected('chantier-seism') || isLayerSelected('chanter-astre-and-seism')
  const viewParam = view === 'sch' ? 'full_rgi_track_sch_flat' : 'full_rgi_track_geo'
  const chantierResponse = await get<OnScreenChantierSearchResult>(
    `/chartis/v2/layer/${CHARTIS_SOURCES_IDS.chantier}/search/${viewParam}/`,
    {
      columns: 'id,libelle,num_compte_geremi,numero_astre,lrs_ligne,lrs_voie,lrs_pk',
      bpolygon: bbox,
      size_per_layer: 50,
      ...(years.length ? { annee__in: years.join(',') } : {}),
      ...(phases.length ? { phase__in: phases.join(',') } : {}),
      ...(filterASTRE && { numero_astre__isnull: false }),
      ...(filterSEISM && { seism_project_id__isnull: false }),
      ...additionalParams,
    },
  )
  return chantierResponse
}
