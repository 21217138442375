import { Feature } from 'geojson'
import './ResultTable.scss'

interface Props {
  features: Feature[]
  columns: { [key: string]: string }
  handleHoverObject: (id: string) => () => void
  handleClickObject: (feature: Feature) => () => void
  noObjectMessage?: string
}

const getValue = (feature: Feature, key: string) => {
  if (key === 'libelle') return feature.properties?.libelle_long || feature.properties?.libelle || 'N/C'
  return feature.properties[key]
}

export default function ResultTable({
  features,
  columns,
  handleClickObject,
  handleHoverObject,
  noObjectMessage,
}: Props) {
  return (
    <table className="result-table">
      {features.length ? (
        <>
          <thead>
            <tr>
              {Object.entries(columns).map(([key, value]) => (
                <th key={key}>{value}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {features.map(feature => (
              <tr
                key={feature.properties.id}
                onMouseEnter={handleHoverObject(feature.properties.id)}
                onClick={handleClickObject(feature)}
              >
                {Object.keys(columns).map(key => (
                  <td key={key} title={getValue(feature, key)}>{getValue(feature, key)}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </>
      ) : (<span>{noObjectMessage}</span>) }
    </table>
  )
}
