import { MapDataSignal, CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { useActivePerimeterParams, useMapURL } from 'utils'
import PointLayer from './generic/PointLayer'

export default function ADVLayer({
  view, visibility, mapRef,
}: LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  const url = useMapURL(
    CHARTIS_SOURCES_IDS.adv,
    view,
    CHARTIS_SOURCES_IDS.adv,
    mapRef,
    perimeterParam,
  )

  const hoveredObjectsIds = MapDataSignal.hoveredObjects.value?.map(o => o.properties?.id) || []
  const { opacity, lineFilter } = MapDataSignal
  return (
    <PointLayer
      url={url}
      sourceId={CHARTIS_SOURCES_IDS.adv}
      layers={[MAPBOX_LAYER_IDS.advCircle, MAPBOX_LAYER_IDS.advHighlight, MAPBOX_LAYER_IDS.advLabel]}
      color="#FF9313"
      hoveredObjectsIds={hoveredObjectsIds}
      opacity={opacity}
      lineFilter={lineFilter.value}
      visibility={visibility}
      textField={['format', 'ADV', '\n', ['get', 'libelle']]}
    />
  )
}
