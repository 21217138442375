import { deleteRequest, get } from '@osrdata/app_core/dist/requests'
import { signal } from '@preact/signals-react'
import { ToastLevel, ToastSignal } from 'components'
import { Views } from 'types'
import { catchable } from 'utils'

export const ViewSignal = signal<Views[]>([])
export const getViews = async () => {
  ViewSignal.value = await get<Views[]>(
    '/dexcarto/users/views',
    { ordering: '-creation_date' },
  )
}

export const deleteView = async (id: string) => {
  const [error] = await catchable(() => deleteRequest(`/dexcarto/users/views/${id}`), true)
  if (error) return
  ToastSignal.value = {
    severity: ToastLevel.INFO,
    message: 'La vue a été supprimée',
  }
  getViews()
}
