import { FeatureOf, Polygon } from '@nebula.gl/edit-modes'
import { Editor } from 'react-map-gl-draw'
import {
  cancelPerimeterDraw, editHandleStyle, editorFeatureStyle,
  handleConfirmEditGeometry,
  perimeterAddFeature, PerimeterSignals, startPerimeterDraw, updatePerimeterFeatures,
  updatePerimeterViewport,
} from 'services'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg'
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg'
import { Button, ButtonStyle, ConfirmModal, ModalSignal, SavePerimeterModal } from 'components'
import { PanelSignal } from 'pages/home/panels/PanelsManager'
import PerimeterInfoPanel from 'pages/home/panels/perimeterInfo/PerimeterInfoPanel'

import './EditorLayer.scss'

export default function EditorLayer() {
  const onUpdate = (payload : { data: FeatureOf<Polygon>[], editType: string }) => {
    if (payload.data.length > 0) {
      updatePerimeterFeatures(payload.data)
    }
    if (payload.editType === 'addFeature') {
      perimeterAddFeature()
    }
  }

  if (!PerimeterSignals.mode.value) return null
  return (
    <div className="editor-layer">
      <div className="editor-layer-actions">
        <Button
          text="Abandonner"
          icon={(<CloseIcon />)}
          onClick={cancelPerimeterDraw}
          style={ButtonStyle.secondary}
        />
        <Button
          text="Recommencer"
          icon={(<RefreshIcon />)}
          onClick={startPerimeterDraw}
          style={ButtonStyle.secondary}
        />
        <Button
          text="Continuer"
          icon={(<ArrowRightIcon />)}
          onClick={() => {
            if (PerimeterSignals.activePerimeter.value) {
              ModalSignal.value = (
                <ConfirmModal
                  title="Voulez-vous confirmer la modification de votre périmètre ?"
                  handleClose={() => { ModalSignal.value = undefined }}
                  handleValidate={async () => {
                    const [error, perim] = await handleConfirmEditGeometry()
                    if (error) return
                    ModalSignal.value = undefined
                    updatePerimeterViewport(perim)
                    PanelSignal.value = <PerimeterInfoPanel perimeter={perim} />
                  }}
                />
              )
            } else {
              ModalSignal.value = (
                <SavePerimeterModal perimeterGeometry={PerimeterSignals.features.value} />
              )
            }
          }}
          disabled={!PerimeterSignals.features.value?.length}
        />
      </div>
      <Editor
        clickRadius={12}
        mode={PerimeterSignals.mode.value.mode}
        onUpdate={onUpdate}
        features={PerimeterSignals.features.value}
        featureStyle={editorFeatureStyle}
        editHandleShape="circle"
        editHandleStyle={editHandleStyle}
      />
    </div>
  )
}
