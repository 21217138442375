import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg'
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg'
import { Button, ButtonStyle, ModalSignal } from 'components'
import { Perimeter } from 'types'
import { useRef, useState } from 'react'
import { useClickOutside } from 'utils'
import { startPatchPerimeterGeometry, startPatchPerimeterProps } from 'services'
import PatchPerimeterModal from 'components/modals/perimeter/PatchPerimeterModal'
import InfosTab from './tabs/InfosTab'

import './PerimeterInfoPanel.scss'

type Props = {
  perimeter: Perimeter
  onBack?: () => void
}

export default function PerimeterInfoPanel({ perimeter, onBack }: Props) {
  const menuWrapperRef = useRef<HTMLDivElement>(null)
  const [menuDisplayed, setMenuDisplayed] = useState(false)

  const closeMenu = () => setMenuDisplayed(false)
  const toggleMenu = () => setMenuDisplayed(!menuDisplayed)

  useClickOutside(menuWrapperRef, closeMenu)

  const handleEditGeometry = () => {
    startPatchPerimeterGeometry(perimeter)
  }

  const handleEditProperties = () => {
    startPatchPerimeterProps(perimeter)
    ModalSignal.value = (<PatchPerimeterModal perimeter={perimeter} />)
  }

  return (
    <>
      {!onBack && <h3 className="panel-title">{`Périmètre ${perimeter.properties.name}`}</h3>}
      {onBack && (
        <div className="panel-title">
          <Button
            className="button-back"
            style={ButtonStyle.borderLess}
            text=""
            icon={<ArrowLeftIcon />}
            onClick={onBack}
          />
          <h3>{`Périmètre ${perimeter.properties.name}`}</h3>
        </div>
      )}
      <div className="perimeter-info-panel panel">
        <div className="divider" />
        <div className="content">
          <div className="actions">
            <div className="settings-menu" ref={menuWrapperRef}>
              <Button
                className="settings-button"
                style={ButtonStyle.borderLess}
                text=""
                icon={<SettingsIcon />}
                onClick={toggleMenu}
              />
              <ul className={menuDisplayed ? 'displayed' : ''}>
                <li>
                  <a onClick={handleEditGeometry} className="flex-center">
                    Modifier la géométrie
                  </a>
                </li>
                <li>
                  <a onClick={handleEditProperties} className="flex-center">
                    Modifier les propriétés
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <InfosTab perimeter={perimeter} />
        </div>
      </div>
    </>
  )
}
