import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { get } from '@osrdata/app_core/dist/requests'
import { Perimeter } from 'types'
import { PerimeterSignals } from 'services'
import ToolsBar from './header/ToolsBar'
import ToolsPanel from './menu/ToolsPanel'
import PanelsManager from './panels/PanelsManager'
import ContentsManager from './contents/ContentsManager'

import './HomePage.scss'

export default function HomePage() {
  const { perimeterId } = useParams<{ perimeterId?: string }>()

  useEffect(() => {
    if (perimeterId) {
      get<Perimeter>(`/dexcarto/users/study-perimeters/${perimeterId}/`)
        .then(perimeter => {
          PerimeterSignals.activePerimeter.value = perimeter
        })
        .catch(() => {
          PerimeterSignals.activePerimeter.value = null
        })
    } else {
      PerimeterSignals.activePerimeter.value = null
    }
  }, [perimeterId])

  return (
    <div id="home" className="page">
      <ToolsBar />
      <ToolsPanel />
      <main>
        <ContentsManager />
        <PanelsManager />
      </main>
    </div>
  )
}
