import { NavigationControl, ScaleControl, _useMapControl as useMapControl, ViewportProps } from 'react-map-gl'
import { Signal } from '@preact/signals-core'
import { ReactComponent as ZoomOutIcon } from 'assets/icons/zoom-out.svg'
import { ReactComponent as GeoSyncIcon } from 'assets/icons/geo-sync.svg'
import { ContentType } from 'types'
import {
  ContentSignals,
  PerimeterSignals,
  calculateGeometryViewport,
  reprojectViewport,
  GEO_DEFAULT_VIEWPORT,
  SCH_DEFAULT_VIEWPORT,
} from 'services'
import { useEffect, useState } from 'react'

import './MapControls.scss'

type Props = {
  data: Signal<Omit<ContentType, 'id'>>
}

export default function MapControls({ data }: Props) {
  const [defaultGeoVP, setDefaultGeoVP] = useState<ViewportProps>(GEO_DEFAULT_VIEWPORT)
  const [defaultSchVP, setDefaultSchVP] = useState<ViewportProps>(SCH_DEFAULT_VIEWPORT)

  useEffect(() => {
    if (PerimeterSignals.activePerimeter.value) {
      const { width, height } = data.value.vp
      if (!width || !height) return
      const { latitude, longitude, zoom } = calculateGeometryViewport(
        PerimeterSignals.activePerimeter.value.geometry,
        { width, height },
      )
      const viewport: Partial<ViewportProps> = { ...data.value.vp, latitude, longitude, zoom }
      setDefaultSchVP(viewport)
      reprojectViewport(viewport, 'sch', 'geo').then(vp => setDefaultGeoVP(vp))
    } else {
      setDefaultGeoVP(GEO_DEFAULT_VIEWPORT)
      setDefaultSchVP(SCH_DEFAULT_VIEWPORT)
    }
  }, [PerimeterSignals.activePerimeter.value])

  const defaultViewport = data.value.type === 'geo' ? defaultGeoVP : defaultSchVP
  const { containerRef } = useMapControl({
    captureDrag: true, captureClick: true, captureDoubleClick: true, captureScroll: true, capturePointerMove: true,
  })

  const handleZoomOut = () => {
    data.value = { ...data.value, vp: defaultViewport }
  }

  const handleGeoSync = () => {
    const [leftData, rightData] = [ContentSignals.left.data, ContentSignals.right.data]
    const oppositeData = leftData.value.vp.latitude === data.value.vp.latitude ? rightData : leftData

    if (oppositeData.value.type === data.value.type) {
      data.value = { ...data.value, vp: oppositeData.value.vp }
    } else {
      reprojectViewport(oppositeData.value.vp, oppositeData.value.type, data.value.type).then(vp => {
        data.value = { ...data.value, vp }
      })
    }
  }

  return (
    <div className="map-controls" ref={containerRef}>
      {data.value.type === 'geo' && (
        <div className="scale-control flex-center">
          <ScaleControl maxWidth={70} unit="metric" style={{ position: 'relative' }} />
        </div>
      )}
      <div className="map-control-buttons">
        {['geo', 'sch'].includes(ContentSignals.left.data.value?.type)
        && ['geo', 'sch'].includes(ContentSignals.right.data.value?.type)
        && (
          <div className="map-control-button geo-sync">
            <button
              type="button"
              className="flex-center"
              onClick={handleGeoSync}
            >
              <GeoSyncIcon />
            </button>
          </div>
        )}
        <div className="map-control-button">
          <button
            type="button"
            className="flex-center"
            onClick={handleZoomOut}
          >
            <ZoomOutIcon />
          </button>
        </div>
        <NavigationControl
          className="navigation-control"
          showZoom={false}
          style={{ position: 'relative' }}
        />
      </div>
    </div>
  )
}
