import { Layer, Source } from 'react-map-gl'
import { MapDataSignal, CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { hoverColor, useActivePerimeterParams, useMapURL } from 'utils'

const sourceLayer = `label-${CHARTIS_SOURCES_IDS.ihm}`

export default function IHMLabelLayer({ view, mapRef, visibility }:LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  const url = useMapURL(
    CHARTIS_SOURCES_IDS.ihm,
    view,
    sourceLayer,
    mapRef,
    perimeterParam,
  )
  const hoveredObjectsIds = MapDataSignal.hoveredObjects.value?.map(o => o.properties?.id) || []

  return (
    <Source
      id={sourceLayer}
      type="vector"
      url={url}
    >
      <Layer
        id={MAPBOX_LAYER_IDS.ihmLabel}
        type="symbol"
        paint={{ 'text-color': '#fff' }}
        layout={{
          'icon-image': ['concat', 'rectanglearrondi-', hoverColor('#0066FF', hoveredObjectsIds)],
          'icon-text-fit': 'both',
          'icon-allow-overlap': false,
          'text-font': ['Open Sans Bold'],
          'text-allow-overlap': false,
          'text-size': 12,
          'text-justify': 'left',
          'text-field': ['get', 'libelle'],
          'text-max-width': 1000,
          visibility: visibility ? 'visible' : 'none',
        }}
        source-layer={CHARTIS_SOURCES_IDS.ihm}
        minzoom={11}
      />
    </Source>
  )
}
