/* eslint-disable camelcase */
import { Accordion } from 'components'
import { useEffect, useState } from 'react'
import { Perimeter } from 'types'
import './InfosTab.scss'

type LineData = { [key: string]: {track_name: string, start_pk: string, end_pk:string}[]}

export default function InfosTab({ perimeter }: {perimeter: Perimeter}) {
  const [data, setData] = useState<LineData>({})
  useEffect(() => {
    const linesData: LineData = {}
    perimeter.properties.lvp_perimeter.reduce((acc, line) => {
      const { line_code, track_name, start_pk, end_pk } = line

      if (acc[line_code]) {
        acc[line_code].push({ track_name, start_pk, end_pk })
      } else {
        acc[line_code] = [{ track_name, start_pk, end_pk }]
      }
      return acc
    }, linesData)
    setData(linesData)
  }, [perimeter])

  return (
    <div className="infos-tab">
      <h3>Infos</h3>
      <h4 className="color-blue">Description</h4>
      <p>{perimeter.properties.description || 'Aucune description renseignée'}</p>
      <h4 className="color-blue">Lignes / Voies</h4>
      <div className="lines">
        {Object.entries(data).map(([line, tracks]) => (
          <Accordion key={line} title={line} className="line-accordion" defaultExpanded={false}>
            {tracks.map(track => (
              <div key={crypto.randomUUID()} className="track-details">
                <span>{track.track_name}</span>
                <span>{`PK ${track.start_pk} à PK ${track.end_pk}`}</span>
              </div>
            ))}
          </Accordion>
        ))}
      </div>
    </div>
  )
}
