/* eslint-disable react/no-danger */
import { useState } from 'react'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg'
import terms from 'assets/terms'
import { Button, ButtonSize, ButtonStyle, ModalSignal } from 'components'
import { CheckInput, TextInput } from 'components/inputs'

import './ReportModal.scss'

type Props = {
  title: string
  type: string
}

export default function ReportModal({ title, type }: Props) {
  const [subject, setSubject] = useState('')
  const [body, setBody] = useState('')
  const [sendBrowserInfo, setSendBrowserInfo] = useState(false)
  const { navigator, screen, location } = window
  const { userAgent, platform } = navigator

  const getBody = () => `${
    body.replace(/\n/g, '%0D%0A')
  }`.concat(sendBrowserInfo ? `
    %0D%0A%0D%0A-------------------
      %0D%0A%0D%0ANavigateurs installés : ${
  userAgent.replace(/\n/g, '%0D%0A')
}%0D%0ASystème d'exploitation : ${
  platform
}%0D%0AURL : ${
  location.href.replace('?', '%3F').replace('&', '%26')
}%0D%0ALargeur de l'écran : ${
  screen.width
}%0D%0AHauteur de l'écran : ${
  screen.height
}` : '')

  const handleClose = () => { ModalSignal.value = undefined }

  const handleSend = () => {
    // eslint-disable-next-line max-len
    const mailto = `mailto:dexcarto.moe@dgexsol.fr?subject=[DEXCarto ${type}] - ${subject ?? 'N/C'}&body=${getBody()}`
    window.location.href = mailto
    handleClose()
  }

  return (
    <div className="report-modal">
      <p className="info flex-column-center">
        <h3>{title}</h3>
      </p>
      <TextInput label={terms.Modals.Report.subject} onChange={setSubject} />
      <textarea placeholder={terms.Modals.Report.comment} onChange={e => setBody(e.target.value)} />
      <CheckInput
        defaultValue={false}
        label={terms.Modals.Report.sendBrowserInfo}
        onChange={setSendBrowserInfo}
      />
      <div className="flex-center actions">
        <Button
          size={ButtonSize.medium}
          style={ButtonStyle.light}
          text={terms.Common.abort}
          onClick={handleClose}
          icon={<CloseIcon />}
        />
        <Button
          size={ButtonSize.medium}
          text={terms.Common.send}
          onClick={handleSend}
          icon={<MailIcon />}
          disabled={!body}
          isAsync
        />
      </div>
    </div>
  )
}
