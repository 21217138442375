import { signal } from '@preact/signals-react'
import { useEffect, useRef, useState } from 'react'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'

import './PanelsManager.scss'

/**
 * Signal used to communicate with right panel
 * which is responsible for displaying/hiding the content
*/
export const PanelSignal = signal<React.ReactNode>()

export default function PanelsManager() {
  const managerRef = useRef<HTMLDivElement>(null)
  const [displayed, setDisplayed] = useState(false)

  const handleClose = () => {
    setDisplayed(false)
  }

  useEffect(() => {
    if (!PanelSignal.value) return
    setDisplayed(true)
  }, [PanelSignal.value])

  useEffect(() => {
    if (!managerRef.current) return undefined

    const handleTransitionEnd = () => {
      if (displayed) return
      PanelSignal.value = undefined
    }

    managerRef.current.addEventListener('transitionend', handleTransitionEnd)
    return () => managerRef?.current?.removeEventListener('transitionend', handleTransitionEnd)
  }, [managerRef.current, displayed])

  return (
    <div ref={managerRef} className={`panels-manager hide-scroll${displayed ? ' displayed' : ' hidden'}`}>
      <CloseIcon className="close" onClick={handleClose} />
      {PanelSignal.value}
    </div>
  )
}
