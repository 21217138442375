import { MutableRefObject, useMemo } from 'react'
import { MapRef } from 'react-map-gl'
import {
  CHARTIS_SOURCES_IDS,
  getGeoTrackFilter, isLayerSelected, isSomeChildSelected, PerimeterSignals,
} from 'services'
import { POSTES_LAYER_KEYS } from 'assets/layers'
import {
  AccesLayer, ADVLayer, ADVLineLayer, BALLayer, BifLayer, CDVLayer, ChantierLayer, CSSLayer, DBCLayer,
  ExploitationModeLayer, IHMLabelLayer, IHMLayer, JDZLayer, LocalisateurLayer, PedaleLayer, PosteLayer, RacLayer,
  SignalLayer, SousStationLayer, StationLayer, TrackLayer, ZAPLabelLayer, ZAPLayer,
} from './layers'
import PassageNiveauLayer from './layers/PassageNiveauLayer'

interface Props {
  mapRef?: MutableRefObject<MapRef>
}
export default function GeoLayers({ mapRef }: Props) {
  const geoTrackFilter = useMemo(
    () => getGeoTrackFilter(PerimeterSignals.activePerimeter.value),
    [PerimeterSignals.activePerimeter.value?.id],
  )

  return (
    <>
      <ExploitationModeLayer
        mapRef={mapRef}
        view="full_rgi_track_geo"
        visibility={isLayerSelected('exploitation-mode-unique')
          || isLayerSelected('exploitation-mode-banalisee') || isLayerSelected('exploitation-mode-ipcs')}
      />
      <TrackLayer
        mapRef={mapRef}
        source={CHARTIS_SOURCES_IDS.trackGeo}
        trackFilter={geoTrackFilter}
      />
      <JDZLayer
        mapRef={mapRef}
        view="full_rgi_track_geo"
        visibility={isLayerSelected('jdz')}
        angle="angle_rgi_track_geo"
      />
      <ZAPLayer
        mapRef={mapRef}
        view="full_rgi_track_geo"
        visibility={isLayerSelected('zoneActionPoste')}
      />
      <BALLayer
        mapRef={mapRef}
        view="full_rgi_track_geo"
        visibility={isLayerSelected('bal-zone')}
      />
      <IHMLayer
        mapRef={mapRef}
        view="full_rgi_track_geo"
        visibility={isLayerSelected('ihm-zone')}
      />
      <ADVLineLayer
        mapRef={mapRef}
        view="full_rgi_track_geo"
        visibility={isLayerSelected('adv')}
      />
      <CDVLayer
        mapRef={mapRef}
        view="full_rgi_track_geo"
        visibility={isLayerSelected('cdv')}
      />
      <RacLayer
        mapRef={mapRef}
        view="full_rgi_track_geo"
        visibility={isLayerSelected('rac')}
      />
      <ChantierLayer
        mapRef={mapRef}
        view="full_rgi_track_geo"
        visibility={isSomeChildSelected('Par année de travaux') && isSomeChildSelected('Par phase')}
      />
      <ADVLayer
        mapRef={mapRef}
        view="full_rgi_track_geo"
        visibility={isLayerSelected('adv')}
      />
      <LocalisateurLayer
        mapRef={mapRef}
        view="full_rgi_track_geo"
        visibility={isLayerSelected('localisateur')}
      />
      <PedaleLayer
        mapRef={mapRef}
        view="full_rgi_track_geo"
        visibility={isLayerSelected('pedale')}
      />
      <AccesLayer
        mapRef={mapRef}
        view="full_rgi_track_geo"
        visibility={isLayerSelected('acces')}
      />
      <BifLayer
        mapRef={mapRef}
        view="full_rgi_track_geo_centroid"
        visibility={isLayerSelected('bif')}
      />
      <DBCLayer
        mapRef={mapRef}
        view="full_rgi_track_geo"
        visibility={isLayerSelected('dbc')}
        angle="angle_rgi_track_geo"
      />
      <PassageNiveauLayer
        mapRef={mapRef}
        view="full_rgi_track_geo"
        visibility={isLayerSelected('passageNiveau')}
        angle="angle_rgi_track_geo"
      />
      <StationLayer
        mapRef={mapRef}
        view="full_rgi_track_geo_centroid"
        visibility={isLayerSelected('station')}
      />
      <SousStationLayer
        mapRef={mapRef}
        view="full_rgi_track_geo"
        visibility={isLayerSelected('sousStation')}
      />
      <CSSLayer
        mapRef={mapRef}
        view="full_rgi_track_geo"
        visibility={isLayerSelected('css')}
      />
      <SignalLayer
        mapRef={mapRef}
        view="full_rgi_track_geo"
        visibility={isSomeChildSelected('Signaux')}
        angle="angle_rgi_track_geo"
      />
      <PosteLayer
        mapRef={mapRef}
        view="full_rgi_track_geo_centroid"
        visibility={Object.values(POSTES_LAYER_KEYS).some(isLayerSelected)}
      />
      <ZAPLabelLayer
        mapRef={mapRef}
        view="full_rgi_track_geo_libelle"
        visibility={isLayerSelected('zoneActionPoste')}
      />
      <IHMLabelLayer
        mapRef={mapRef}
        view="full_rgi_track_geo_libelle"
        visibility={isLayerSelected('ihm-zone')}
      />
    </>
  )
}
