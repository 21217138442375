import sncflogo from 'assets/logos/sncflogo.svg'
import d2dlogo from 'assets/logos/d2dlogo.svg'
import dexcartologo from 'assets/logos/DEXCARTO.png'
import dexlogo from 'assets/logos/DEX.png'
import AccountMenu from './account/AccountMenu'

import './TopBar.scss'

type Props = {
  appName: string;
}

export default function TopBar({ appName }: Props) {
  return (
    <header>
      <div className="logos">
        <a className="flex-center">
          <img alt={appName} src={sncflogo} />
          <img alt={appName} src={d2dlogo} />
          <img alt={appName} className="dexlogo" src={dexlogo} />
          <img alt={appName} src={dexcartologo} />
        </a>
      </div>
      <AccountMenu />
    </header>
  )
}
