import { MapDataSignal, CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { useActivePerimeterParams, useMapURL } from 'utils'
import PointLayer from './generic/PointLayer'

export default function PedaleLayer({
  view, mapRef, visibility,
}: LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat_centroid__bpolygon')
  const url = useMapURL(
    CHARTIS_SOURCES_IDS.bif,
    view,
    CHARTIS_SOURCES_IDS.bif,
    mapRef,
    { ...perimeterParam, code_ch: 'BF' },
  )
  const hoveredObjectsIds = MapDataSignal.hoveredObjects.value?.map(o => o.properties?.id) || []
  const { opacity, lineFilter } = MapDataSignal

  return (
    <PointLayer
      url={url}
      sourceId={CHARTIS_SOURCES_IDS.bif}
      layers={[
        MAPBOX_LAYER_IDS.bifCircle,
        MAPBOX_LAYER_IDS.bifHighlight,
        MAPBOX_LAYER_IDS.bifLabel,
      ]}
      color="#9d15cf"
      hoveredObjectsIds={hoveredObjectsIds}
      opacity={opacity}
      lineFilter={lineFilter.value}
      visibility={visibility}
      textField={['format', 'Bifurcation', '\n', ['get', 'libelle']]}
    />
  )
}
