/** Contents manager services */
import { signal } from '@preact/signals-react'
import { ContentType } from 'types'
import { useEffect, useState } from 'react'
import { parseURLToViewport } from './params'

/**
 * Controls left and right content displayed in ContentsManager
 */
export const ContentSignals = {
  left: {
    data: signal<ContentType>(),
    element: signal<JSX.Element>(),
  },
  right: {
    data: signal<ContentType>(),
    element: signal<JSX.Element>(),
  },
}

/**
 * Get ContentType object for param
 *
 * example :
 * string params looks like 'geo,0-0' if geo or sch
 * string params looks like 'doc,123' if doc
 *
 * @param para - param string to decode
 * @returns - ContentType object
 */
export const getContentForParam = (param: string): ContentType => {
  const decodedParam = decodeURIComponent(param)
  const [type, value] = decodedParam.split(',')

  switch (type) {
    case 'geo':
    case 'sch':
      return {
        type: type as ContentType['type'],
        vp: parseURLToViewport(value),
      }
    case 'doc':
      return {
        type: type as ContentType['type'],
        id: value,
      }
    case 'table':
      return {
        type: type as ContentType['type'],
        vp: parseURLToViewport(value),
      }
    default:
      return undefined
  }
}

/**
 * Get content params values from URL
 *
 * @param urlString - ex: http://localhost:3000/?vl=geo%2C2.50767_43.8443_7.74_0_0&l=2.3.1%2C2.3.2%2C2.3.3%2C2.3
 * @returns - Object containing layers, left and right values
 */
export const getContentsFromURL = (urlString: string) => {
  const url = new URL(urlString)
  const params = new URLSearchParams(url.search)

  return {
    layers: params.get('l'),
    left: params.get('vl'),
    right: params.get('vr'),
  }
}

type Tab = {
  position: 'left' | 'right'
  type: 'geo' | 'sch'
}

export const useTabs = () => {
  const [possibleTabs, setPossibleTabs] = useState<Tab[]>([])
  const [selectedTab, setSelectedTab] = useState<Tab | null>(null)

  const { data: leftData } = ContentSignals.left
  const { data: rightData } = ContentSignals.right

  useEffect(() => {
    const tabs: Tab[] = []
    if (leftData.value?.type === 'geo' || leftData.value?.type === 'sch') {
      tabs.push({ position: 'left', type: leftData.value.type })
    }
    if (rightData.value?.type === 'geo' || rightData.value?.type === 'sch') {
      tabs.push({ position: 'right', type: rightData.value.type })
    }
    setPossibleTabs(tabs)
    if (!tabs.map(t => t.position).includes(selectedTab?.position)) {
      setSelectedTab(tabs[0] ?? null)
    }
  }, [leftData.value?.type, rightData.value?.type])

  return { possibleTabs, selectedTab, setSelectedTab }
}
