import { Layer, Source } from 'react-map-gl'
import { MapDataSignal, CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { hoverColor, useActivePerimeterParams, useMapURL } from 'utils'

export default function IHMLayer({ view, mapRef, visibility }:LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  const url = useMapURL(
    CHARTIS_SOURCES_IDS.ihm,
    view,
    CHARTIS_SOURCES_IDS.ihm,
    mapRef,
    perimeterParam,
  )
  const hoveredObjectsIds = MapDataSignal.hoveredObjects.value?.map(o => o.properties?.id) || []

  return (
    <Source
      id={CHARTIS_SOURCES_IDS.ihm}
      type="vector"
      url={url}
    >
      <Layer
        id={MAPBOX_LAYER_IDS.ihmOutline}
        type="line"
        paint={{
          'line-width': ['step', ['zoom'], 2, 13, 4],
          'line-pattern': [
            'case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 'ihm_line_red',
            'ihm_line',
          ],
        }}
        layout={{
          visibility: visibility ? 'visible' : 'none',
          'line-cap': 'square',
          'line-join': 'miter',
        }}
        minzoom={11}
        source-layer={CHARTIS_SOURCES_IDS.ihm}
      />
      <Layer
        id={MAPBOX_LAYER_IDS.ihmFill}
        type="fill"
        paint={{
          'fill-color': hoverColor('transparent', hoveredObjectsIds),
          'fill-opacity': 0.2,
        }}
        layout={{
          visibility: visibility ? 'visible' : 'none',
        }}
        minzoom={11}
        source-layer={CHARTIS_SOURCES_IDS.ihm}
      />
    </Source>
  )
}
