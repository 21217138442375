import { MapDataSignal, CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerWithAngleProps } from 'types'
import { useActivePerimeterParams, useMapURL } from 'utils'
import PictoLayer from './generic/PictoLayer'

export default function DBCLayer({
  view, visibility, mapRef, angle,
}: LayerWithAngleProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  const url = useMapURL(
    CHARTIS_SOURCES_IDS.dbc,
    view,
    CHARTIS_SOURCES_IDS.dbc,
    mapRef,
    perimeterParam,
  )

  const hoveredObjectsIds = MapDataSignal.hoveredObjects.value?.map(o => o.properties?.id) || []
  const { opacity, lineFilter } = MapDataSignal
  return (
    <PictoLayer
      url={url}
      sourceId={CHARTIS_SOURCES_IDS.dbc}
      layer={MAPBOX_LAYER_IDS.dbc}
      hoveredObjectsIds={hoveredObjectsIds}
      opacity={opacity}
      lineFilter={lineFilter.value}
      visibility={visibility}
      layout={{
        'text-field': [
          'format', 'DBC n°', ['get', 'numero_detecteur_boite_chaude'], '\n',
          'Capteur ', ['get', 'lrs_voie'], ' : km ', ['get', 'lrs_pk'],
        ],
        'icon-image': 'dbc',
        'icon-rotate': ['+', ['coalesce', ['get', angle], 0], -90],
        'icon-size': 0.8,
      }}
    />
  )
}
